import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useContext
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { NATIONAL_ZOOM } from 'screens/Property/helpers/constants';
import {
  getUpdatedBoundingBox,
  getZoomByBounds,
  mappingShape
} from 'screens/Property/helpers/propertyDataHelpers';
import { calculateCentroid } from 'screens/Property/helpers/mapApiHelpers';
import { FETCH_TYPES } from 'helpers/constants';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Context } from 'components/Store';
import PropertyMapWrapper from '../PropertyLandingMap/PropertyMapWrapper';
import CropZoneCard from './CropZoneCard';

const CropZoneCreate = () => {
  const flags = useFlags();
  const [{ organization }] = useContext(Context);
  const { state } = useLocation();
  const { fieldId, action, cropzoneId } = useParams();
  const { propertyCount } = organization?.properties || {};
  const isHugeOrganization =
    flags.configureLargeGrowerConditionals?.propertiesThreshold &&
    propertyCount >= flags.configureLargeGrowerConditionals.propertiesThreshold;

  const {
    fieldsAndAreasGeoJSONCollection,
    loading,
    reloadData
  } = usePopulateMapData({
    fetchType: isHugeOrganization
      ? FETCH_TYPES.paginatedPropertiesMap
      : FETCH_TYPES.propertiesMap
  });
  useEffect(() => {
    if (isHugeOrganization && state?.parentPage) {
      reloadData(null, { page: state.parentPage });
      return;
    }
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbTitle = action === 'edit' ? 'Edit' : 'New';

  const [isSaving, setIsSaving] = useState(false);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedFeatureId, setSelectedFeatureId] = useState();
  const [cropzone, setCropzone] = useState(null);
  const [field, setField] = useState(null);
  const [initialFeatures, setInitialFeatures] = useState({});
  const [zoom, setZoom] = useState(NATIONAL_ZOOM);

  const newCropZoneId = useRef(uuidv4());

  const mapMultiPolygon = useRef(ft => {
    if (ft.geometry.coordinates.length === 1) {
      return mappingShape(ft);
    }

    return ft;
  });

  const geoJSONCentroid = useMemo(() => {
    if (initialFeatures.features) {
      return calculateCentroid(initialFeatures);
    }

    return null;
  }, [initialFeatures]);

  const mapCoordinates = useMemo(() => geoJSONCentroid?.geometry?.coordinates, [
    geoJSONCentroid
  ]);

  useEffect(() => {
    const newFeatures = [];

    const fieldSelected = fieldsAndAreasGeoJSONCollection.features.find(
      ft => ft.properties.id === fieldId
    );

    if (fieldSelected) {
      fieldSelected.properties.$layer = 'default';
      fieldSelected.id = fieldId;
      setField(fieldSelected.properties);
      newFeatures.push(mapMultiPolygon.current(fieldSelected));

      fieldSelected.properties.cropzones.forEach(cz => {
        const selectedCropzone = fieldsAndAreasGeoJSONCollection.features.find(
          ft => ft.properties.id === cz.id
        );

        let layer = 'default';

        if (selectedCropzone.properties.id === cropzoneId) {
          layer = 'selected';
          selectedCropzone.id = cropzoneId;
          setCropzone(selectedCropzone.properties);
        }

        selectedCropzone.properties.$layer = layer;

        newFeatures.push(mapMultiPolygon.current(selectedCropzone));
      });

      if (!cropzoneId) {
        newFeatures.push(
          mapMultiPolygon.current({
            ...fieldSelected,
            id: newCropZoneId.current,
            properties: {
              ...fieldSelected.properties,
              $layer: 'selected'
            }
          })
        );
      }

      const featureCollection = {
        type: 'FeatureCollection',
        features: newFeatures
      };

      const customFitFields = getUpdatedBoundingBox(
        undefined,
        featureCollection,
        false
      );

      const boundsZoom = getZoomByBounds(customFitFields);

      setZoom([boundsZoom - 1]);

      setFeatures(newFeatures);
      setSelectedFeatureId(cropzoneId || newCropZoneId.current);
      setInitialFeatures(featureCollection);
    }
  }, [fieldsAndAreasGeoJSONCollection.features, cropzoneId, fieldId]);

  const handleFeaturesChange = useCallback(
    newFeatures => setFeatures(_.uniqBy(newFeatures, 'id')),
    [setFeatures]
  );

  const handleSelectedFeatures = useCallback(
    featuresId => {
      const selectedCropzones = features.filter(ft =>
        featuresId.includes(ft.id)
      );

      setSelectedFeatures(selectedCropzones);
    },
    [features]
  );

  return (
    <>
      <Breadcrumb disabled>
        <Breadcrumb.Item
          title="Properties"
          value={`${breadcrumbTitle} Crop Zone`}
          isLast
        />
      </Breadcrumb>
      <div className="flex h-full w-full">
        <div className="flex flex-col w-1/3 h-auto m-4">
          <CropZoneCard
            field={field}
            features={selectedFeatures}
            isSaving={setIsSaving}
            cropzone={cropzone}
          />
        </div>
        <div className="relative w-full" id="map-wrapper-container">
          {loading || isSaving || !mapCoordinates ? (
            <Spinner />
          ) : (
            <PropertyMapWrapper
              zoom={zoom}
              setZoom={setZoom}
              currentCoordinates={mapCoordinates}
              shapeIdSelected={selectedFeatureId}
              geoJSONCollection={initialFeatures}
              onSelectFeatures={handleSelectedFeatures}
              onFeaturesChange={handleFeaturesChange}
              setNewFieldFeatures={setSelectedFeatures}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CropZoneCreate;
