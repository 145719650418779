import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CreateFarmModal from 'screens/Property/PropertyCreate/components/CreatePropertyModal';
import useFarmData from 'screens/Property/hooks/useFarmData';
import { Context } from 'components/Store';
import { SEARCH_TEXT_VALUE_FFT } from 'reducers/reducer';
import '../../index.css';
import filterOutlined from 'assets/filter_outlined.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import infoIcon from 'assets/info.svg';
import usePropertyFilters from 'hooks/usePropertyFilters';
import useDebounce from 'hooks/useDebounce';
import Title from './components/Title';
import SearchInputLandingProperties from '../SearchInputLandingProperties';
import PropertyToolbar from '../../PropertyLandingMap/PropertyToolbar';

const PanelHeader = ({
  showTools,
  title,
  showGoBack,
  reloadFarmData,
  openFilters,
  view,
  loading,
  onChangeView
}) => {
  const { createFarm } = useFarmData(null, false);
  const [
    { searchTextValueFFT, organization, selectedProperty },
    dispatch
  ] = useContext(Context);
  const [openFarmModal, setOpenFarmModal] = useState(false);
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
  const { hasActiveFilters } = usePropertyFilters();
  const [searchTerm, setSearchTerm] = useState(
    searchTextValueFFT?.valueInput || ''
  );

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const orgId = Context?._currentValue[0]?.organization?.id;
  const {
    feArchiveField,
    feArchiveFieldOrgIds,
    feFarmsFilter,
    fePropertyDetails,
    fePropertyDetailsByOrgId
  } = useFlags();
  const showArchiveField =
    feArchiveField || feArchiveFieldOrgIds?.indexOf(orgId) !== -1;
  const showPropertyDetails =
    fePropertyDetails || fePropertyDetailsByOrgId?.includes(organization?.id);

  const handleCreateFarm = async name => {
    await createFarm({
      name,
      timeZone: 'US/Central',
      referencePoint: {
        type: 'Point',
        coordinates: [0, 0]
      }
    });

    reloadFarmData();

    setOpenFarmModal(false);
  };

  const handleInputFocus = () => {
    setIsSearchBarFocused(true);
  };

  const handleInputBlur = () => {
    setIsSearchBarFocused(false);
  };

  const showSearchBarInfoMessage =
    hasActiveFilters && (debouncedSearchTerm || isSearchBarFocused);

  useEffect(() => {
    if (debouncedSearchTerm !== searchTextValueFFT?.valueInput) {
      dispatch({
        type: SEARCH_TEXT_VALUE_FFT,
        payload: { valueInput: debouncedSearchTerm }
      });
    }
  }, [debouncedSearchTerm, dispatch, searchTextValueFFT?.valueInput]);

  return (
    <div className="pb-4 sticky top-0 z-4 pt-5 white-bg px-5">
      <div>
        <div className="flex items-center justify-between">
          <Title title={title} showGoBack={showGoBack} />
        </div>
        {showTools && (
          <div>
            <div
              data-testid="selected-property-toolbar"
              className={`justify-left w-full selected-property-toolbar ${selectedProperty.id &&
                'mt-4'}`}
            >
              {selectedProperty.id ? (
                <PropertyToolbar
                  showPropertyDetails={showPropertyDetails}
                  view={view}
                  onChangeView={onChangeView}
                  loading={loading}
                />
              ) : null}
            </div>
            <div className="flex mt-2">
              <div className="w-full pt-1">
                <SearchInputLandingProperties
                  onBlur={handleInputBlur}
                  onFocus={handleInputFocus}
                  onChange={event => {
                    setSearchTerm(event.target.value.toLowerCase());
                  }}
                  placeholder="Search Properties..."
                  value={searchTerm}
                  dispatch={dispatch}
                  drawer
                />
              </div>
              <div className="relative pt-1">
                {(showArchiveField || feFarmsFilter) && (
                  <button
                    data-testid="panel-filter-button"
                    type="button"
                    className="ml-2 w-10 h-8 flex items-center justify-center border rounded-md hover:bg-neutral-100 bg-white"
                    onClick={openFilters}
                  >
                    <img src={filterOutlined} alt="" />
                  </button>
                )}
                {hasActiveFilters && (
                  <div
                    className="w-10px h-10px rounded-full bg-blue-90 absolute"
                    style={{ top: 0, right: '-4px' }}
                  />
                )}
              </div>
            </div>
            {showSearchBarInfoMessage && (
              <div className="flex px-2 mt-2">
                <img alt="info" src={infoIcon} className="" />
                <span className="text-sm text-gray-700 ml-1">
                  Filter will affect search results.
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <CreateFarmModal
        open={openFarmModal}
        onCancel={() => setOpenFarmModal(false)}
        onCreate={handleCreateFarm}
      />
    </div>
  );
};

PanelHeader.defaultProps = {
  showTools: false,
  showGoBack: false,
  reloadFarmData: () => {},
  openFilters: () => {},
  onChangeView: () => {},
  view: 'map'
};

PanelHeader.propTypes = {
  showTools: PropTypes.bool,
  title: PropTypes.string.isRequired,
  showGoBack: PropTypes.bool,
  reloadFarmData: PropTypes.func,
  openFilters: PropTypes.func,
  view: PropTypes.string,
  onChangeView: PropTypes.func,
  loading: PropTypes.bool.isRequired
};

export default PanelHeader;
