/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { set } from 'lodash';
import {
  propertiesV3 as propertiesV3Api,
  farm,
  propertiesV4 as propertiesV4Api
} from 'utilities/api';

import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import sortTree from 'helpers/sortTree';
import { DUMMY_SEASON, FETCH_TYPES } from 'helpers/constants';
import axios from 'axios';
import { parseServerError } from 'helpers/errorHelpers';
import { SET_LOAD_COSTS, SET_LOAD_PROPERTIES } from 'reducers/reducer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  propertyDataReducer,
  resetPropertyData,
  PROPERTY_DATA_INIT_RELOAD,
  PROPERTY_DATA_LOAD_PROPERTIES,
  PROPERTY_DATA_SET_FIELDS_LOADED,
  PROPERTY_DATA_SET_GEOJSON_COLLECTION_FEATURES,
  PROPERTY_DATA_CALCULATE_CENTROID,
  PROPERTY_DATA_RESET_MAP_VIEW,
  PROPERTY_DATA_SET_FIELDS,
  PROPERTY_DATA_SET_MAP_CENTROID,
  PROPERTY_DATA_SET_ZOOM,
  SET_PROPERTY_COSTS,
  PROPERTY_PAGINATED_DATA_INIT_RELOAD,
  PROPERTY_DATA_PAGINATED_LOAD_PROPERTIES,
  RESET_PRELOADED_PAGES
} from '../screens/Property/helpers/propertyDataReducer';
import usePropertyFilters from './usePropertyFilters';

const usePopulateMapData = ({
  fetchType,
  cropSeasonsId,
  isCacheOn = false,
  showDanglingFields = false
}) => {
  const { configureLargeGrowerConditionals } = useFlags();

  // eslint-disable-next-line no-use-before-define
  const dataToBeSorted = useRef();
  const [, dispatch] = useContext(Context);
  const [
    {
      data,
      beforeSearch,
      zoom,
      fieldsAndAreasCentroidCoordinates,
      fieldsAndAreasGeoJSONCollection,
      loading,
      fieldsLoading,
      costs,
      isLoadingPaginatedProperties
    },
    propertyDispatch
  ] = useReducer(propertyDataReducer, resetPropertyData(), resetPropertyData);
  const { runMapFilter } = usePropertyFilters();

  const filteredGeoJSON = useMemo(
    () => runMapFilter(fieldsAndAreasGeoJSONCollection),
    [fieldsAndAreasGeoJSONCollection, runMapFilter]
  );

  const handleError = useCallback(
    err => {
      if (axios.isCancel(err)) {
        catchCancel(err);
      } else {
        parseServerError(dispatch)(err);
      }
    },
    [dispatch]
  );

  const setGeoJSONCollectionFeatures = useCallback((value, isEdition) => {
    return propertyDispatch({
      type: PROPERTY_DATA_SET_GEOJSON_COLLECTION_FEATURES,
      payload: !isEdition
        ? value
        : {
            isEdition,
            value
          }
    });
  }, []);

  const initialSettings = useCallback(
    (body, isPaginated = false, hideLoader = false) => {
      let seasonIds = JSON.parse(localStorage.getItem('selectedCropSeasons'));
      if (seasonIds === null) {
        seasonIds = [DUMMY_SEASON];
      }
      const v3PropertiesPostBody = {
        seasonIds
      };

      const postBody = body || v3PropertiesPostBody || {};
      if (!postBody?.seasonIds?.length) {
        postBody.seasonIds = [DUMMY_SEASON];
      }

      propertyDispatch({
        type: isPaginated
          ? PROPERTY_PAGINATED_DATA_INIT_RELOAD
          : PROPERTY_DATA_INIT_RELOAD
      });

      if (!hideLoader) dispatch({ type: SET_LOAD_PROPERTIES, payload: true });

      return postBody;
    },
    [dispatch]
  );

  const fetchPaginatedMapProperties = useCallback(
    async (body, params = { page: 0, textSearch: '', orgId: null }) => {
      const { pageSize } = configureLargeGrowerConditionals;
      const orgChange = !!params.orgId && data.orgId !== params.orgId;
      if (orgChange) propertyDispatch({ type: RESET_PRELOADED_PAGES });
      if (!orgChange && params.textSearch) {
        const isOver =
          (params.page === 0 && data?.pagination?.totalPage === 1) ||
          params.page >= data?.pagination?.totalPages;
        if (isOver) return;
      }
      if (!orgChange && !params.textSearch) {
        const pagination = beforeSearch?.data?.pagination
          ? beforeSearch?.data.pagination
          : data.pagination;
        const isPreloaded = pagination?.preLoadedPages?.includes(params.page);
        const isOver =
          (params.page === 0 && pagination?.totalPage === 1) ||
          params.page >= pagination?.totalPages;

        if (isPreloaded || isOver) {
          propertyDispatch({
            type: PROPERTY_DATA_PAGINATED_LOAD_PROPERTIES,
            payload: {
              properties: [],
              orgId: data.orgId,
              referencePage: pagination.referencePage,
              parentPage: params.page,
              totalPages: pagination.totalPages
            }
          });
          propertyDispatch({ type: PROPERTY_DATA_SET_FIELDS_LOADED });
          return;
        }
      }
      try {
        const postBody = initialSettings(body, true, !params.textSearch);
        const response = await propertiesV4Api.fetchByPost(
          {
            ...postBody,
            include_extended: true
          },
          {
            pageNo: params.page,
            size: pageSize,
            ...(params.textSearch ? { name: params.textSearch } : {})
          },
          isCacheOn,
          showDanglingFields
        ).promise;
        if (response?.data?.data?.properties?.length) {
          propertyDispatch({
            type: PROPERTY_DATA_PAGINATED_LOAD_PROPERTIES,
            payload: {
              ...response.data.data,
              referencePage: params.page,
              parentPage: params.page,
              totalPages: response.data.totalPages,
              textSearch: params.textSearch
            }
          });
        }
      } catch (err) {
        handleError(err);
      } finally {
        propertyDispatch({ type: PROPERTY_DATA_SET_FIELDS_LOADED });
      }
    },
    [
      configureLargeGrowerConditionals,
      data.orgId,
      data.pagination,
      beforeSearch?.data?.pagination,
      handleError,
      initialSettings,
      isCacheOn,
      showDanglingFields,
      propertyDispatch
    ]
  );

  const fetchMapProperties = useCallback(
    async body => {
      try {
        const postBody = initialSettings(body);
        const response = await propertiesV3Api.fetchByPost(
          {
            ...postBody,
            include_extended: true
          },
          isCacheOn,
          showDanglingFields
        ).promise;
        if (response?.data?.properties?.length) {
          propertyDispatch({
            type: PROPERTY_DATA_LOAD_PROPERTIES,
            payload: response.data
          });
        }
      } catch (err) {
        handleError(err);
      } finally {
        propertyDispatch({ type: PROPERTY_DATA_SET_FIELDS_LOADED });
        dispatch({ type: SET_LOAD_PROPERTIES, payload: false });
      }
    },
    [dispatch, handleError, initialSettings, isCacheOn, showDanglingFields]
  );

  const fetchCropSeasonsMapData = useCallback(
    async body => {
      try {
        const seasonIds = [cropSeasonsId].flat();
        const v3PropertiesPostBody = {
          seasonIds
        };

        const postBody = body || v3PropertiesPostBody || {};

        propertyDispatch({ type: PROPERTY_DATA_INIT_RELOAD });
        dispatch({ type: SET_LOAD_PROPERTIES, payload: true });

        const response = await propertiesV3Api.fetchByPost(
          {
            ...postBody,
            include_extended: true
          },
          null,
          isCacheOn,
          showDanglingFields
        ).promise;

        propertyDispatch({
          type: PROPERTY_DATA_LOAD_PROPERTIES,
          payload: response.data
        });
      } catch (err) {
        handleError(err);
      } finally {
        propertyDispatch({ type: PROPERTY_DATA_SET_FIELDS_LOADED });
        dispatch({ type: SET_LOAD_PROPERTIES, payload: false });
      }
    },
    [cropSeasonsId, dispatch, handleError, isCacheOn, showDanglingFields]
  );

  const fetchFarmCosts = useCallback(async (farmId, body) => {
    const costsApi = farm.createChildApi({
      action: `farm/${farmId}/cost`
    });

    try {
      const seasonIds = JSON.parse(localStorage.getItem('selectedCropSeasons'));

      const v3PropertiesPostBody = {
        seasonIds
      };

      const postBody = body || v3PropertiesPostBody || {};

      dispatch({ type: SET_LOAD_COSTS, payload: true });

      if (
        !(postBody.seasonIds?.length > 0) ||
        new Set(postBody.seasonIds).has(DUMMY_SEASON)
      ) {
        propertyDispatch({
          type: SET_PROPERTY_COSTS,
          payload: {
            costs: {},
            farmId
          }
        });

        return;
      }

      const response = await costsApi.post(postBody).promise;

      propertyDispatch({
        type: SET_PROPERTY_COSTS,
        payload: {
          costs: response.data,
          farmId
        }
      });
    } catch (err) {
      if (
        err?.response?.data?.displayErrorMessage ===
        'Could not retrieve any task with the specified filters'
      ) {
        return;
      }
      if (
        err?.response?.data?.displayErrorMessage ===
        'Could not convert from CWF season to Base season'
      ) {
        return;
      }
      handleError(err);
    } finally {
      dispatch({ type: SET_LOAD_COSTS, payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadData = useCallback(
    (body, params) => {
      switch (fetchType) {
        case FETCH_TYPES.propertiesMap:
          return fetchMapProperties(body);
        case FETCH_TYPES.paginatedPropertiesMap:
          return fetchPaginatedMapProperties(body, params);
        case FETCH_TYPES.cropSeasonsMap:
          return fetchCropSeasonsMapData(body);
        default:
          throw new Error(fetchType);
      }
    },
    [
      fetchMapProperties,
      fetchPaginatedMapProperties,
      fetchCropSeasonsMapData,
      fetchType
    ]
  );

  // Get or compute the centroid value
  useEffect(() => {
    if (!loading) {
      return;
    }

    if (data && fieldsAndAreasGeoJSONCollection) {
      if (fieldsAndAreasGeoJSONCollection.features.length > 0) {
        // Once we've loaded all shapes and areas and there ARE applicable shapes and areas, calculate
        // centroid and zoom
        propertyDispatch({
          type: PROPERTY_DATA_CALCULATE_CENTROID
        });
      } else {
        propertyDispatch({
          type: PROPERTY_DATA_RESET_MAP_VIEW
        });
      }
    }
  }, [
    data,
    fieldsAndAreasCentroidCoordinates,
    fieldsAndAreasGeoJSONCollection,
    loading
  ]);

  useMemo(() => {
    dataToBeSorted.current = sortTree(data);
  }, [data]);

  const dataSorted = dataToBeSorted.current;

  const setFields = useCallback(
    value =>
      propertyDispatch({
        type: PROPERTY_DATA_SET_FIELDS,
        payload: value
      }),
    []
  );
  const setFieldsAndAreasCentroidCoordinates = useCallback(
    value =>
      propertyDispatch({
        type: PROPERTY_DATA_SET_MAP_CENTROID,
        payload: value
      }),
    []
  );
  const setZoom = useCallback(value => {
    return propertyDispatch({
      type: PROPERTY_DATA_SET_ZOOM,
      payload: value
    });
  }, []);

  const getSsurgoInfo = () => {
    return Promise.resolve({
      slope: 3,
      soilTexture: 'loam',
      slopeLength: 13,
      hydrologyGroup: 'C'
    });
  };

  const filterFeatureCollectionByParentId = (fc, parentId, parents = []) =>
    fc.features
      ? fc.features.map(feat => {
          const layer = parentId || parents.length ? 'default' : 'focused';
          set(
            feat,
            'properties.$layer',
            parentId === feat.properties?.id ||
              (parentId === feat.properties?.propertyId &&
                !feat.properties?.$parentDuplicated) ||
              parents.some(parent => parent.id === feat.properties?.id)
              ? 'focused'
              : layer
          );
          return feat;
        })
      : [];
  return {
    pagination: data?.pagination,
    fieldsAndAreasCentroidCoordinates,
    fieldsAndAreasGeoJSONCollection: filteredGeoJSON,
    setGeoJSONCollectionFeatures,
    getSsurgoInfo,
    loading,
    reloadData,
    setFields,
    setFieldsAndAreasCentroidCoordinates,
    setZoom,
    zoom,
    dataSorted,
    costs,
    fieldsLoading,
    filterFeatureCollectionByParentId,
    fetchFarmCosts,
    isLoadingPaginatedProperties
  };
};

export default usePopulateMapData;
