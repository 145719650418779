import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  memo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'syngenta-digital-cropwise-react-ui-kit';
import { Spinner } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import { hasRoleAccess, ROLES } from 'utilities/access';
import { findGeoJSON } from 'screens/Property/helpers/propertyDataHelpers';
import { PropertyTypes } from 'helpers/propertyHelpers';
import { COLLAPSE_PROPERTY, EXPAND_PROPERTIES } from 'reducers/reducer';
import createAction from 'helpers/createAction';
import PropertyLandingContext from 'screens/Property/PropertiesLanding/context';
import FarmRow from '../../../FarmRow';
import FieldRow from '../../../FieldRow';
import CropZoneRow from '../../../CropZoneRow';
import UnarchivePropertyModal from '../../../../UnarchivePropertyModal';
import './index.css';

const DefaultTree = ({ properties, propertiesFilter }) => {
  const [
    {
      selectedProperty,
      expandedPropertyIds,
      loggedInUserOrgPermission,
      searchTextValueFFT
    },
    dispatch
  ] = useContext(Context);

  const {
    pagination,
    isHugeOrganization,
    isLoadingPaginatedProperties,
    params,
    setParams,
    isLoadingFields,
    fetchProperties,
    onPropertySelection,
    fieldsAndAreasGeoJSONCollection
  } = useContext(PropertyLandingContext);

  const { role } = loggedInUserOrgPermission;
  const [unarchivePropertyModalOpen, setUnarchivePropertyModalOpen] = useState(
    false
  );

  const closeUnarchivePropertyModal = () => {
    return setUnarchivePropertyModalOpen(false);
  };

  const [loadingPosition, setLoadingPosition] = useState('bottom');
  const [unarchivedProperty, setUnarchivedProperty] = useState({});
  const [unarchivedPropertyType, setUnarchivedPropertyType] = useState(
    PropertyTypes.FARM
  );

  const lastScrollTop = useRef(0);
  const scrollPosition = useRef(0);
  const containerRef = useRef(null);
  const previousScrollHeight = useRef(0);
  const observerRef = useRef(null);

  const handleScroll = useCallback(
    event => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      scrollPosition.current = scrollTop;

      const isLoading = isLoadingPaginatedProperties || isLoadingFields;
      const isScrollingDown = scrollTop + clientHeight >= scrollHeight - 120;
      const isScrollingUp = scrollTop === 0;

      const lazyLoadData = (page, position) => {
        previousScrollHeight.current = scrollHeight;
        setParams(prev => ({ ...prev, page }));
        setLoadingPosition(position);
      };

      if (isScrollingDown && !isLoading) {
        const currPage = pagination?.preLoadedPages?.length
          ? Math.max(...pagination.preLoadedPages)
          : 0;
        lazyLoadData(currPage + 1, 'bottom');
      }

      if (isScrollingUp && (!isLoading || loadingPosition === 'bottom')) {
        const currPage = pagination?.preLoadedPages?.length
          ? Math.min(...pagination.preLoadedPages)
          : 0;
        if (currPage > 0) {
          const container = document.querySelector('[data-tree]');
          if (container) container.scrollTop = 400;
          lazyLoadData(currPage - 1, 'top');
        }
      }
      lastScrollTop.current = scrollTop;
      return () => {
        setParams(prev => ({ ...prev, page: 0 }));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingPaginatedProperties, params, setParams, pagination]
  );

  useEffect(() => {
    const container = document.querySelector('[data-tree]');
    if (container && isHugeOrganization) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container && isHugeOrganization) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, isHugeOrganization]);

  useEffect(() => {
    if (!isHugeOrganization) return;
    const container = containerRef.current;
    if (container && previousScrollHeight.current) {
      if (loadingPosition === 'bottom') {
        const scrollHeightDifference =
          container.scrollHeight - previousScrollHeight.current;
        container.scrollTop += scrollHeightDifference;
      }
      previousScrollHeight.current = 0;
    }
  }, [
    params.page,
    isLoadingPaginatedProperties,
    isLoadingFields,
    loadingPosition,
    isHugeOrganization
  ]);

  /**
   * There's an edge case where most properties in the first page are archived, resulting in
   * only a few properties being displayed and preventing the scroll event from triggering further data loading.
   * The logic below ensures that additional data is loaded initially until scrolling becomes possible.
   */
  useEffect(() => {
    if (
      !isHugeOrganization ||
      isLoadingPaginatedProperties ||
      isLoadingFields ||
      !properties?.length
    ) {
      return;
    }

    let debounceTimeout;
    let shouldCheck = true; // to stop running (disconect observer) when we have enough data already

    const checkIfScrollIsPossible = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollHeight, clientHeight } = container;
        const isNotScrollable = scrollHeight <= clientHeight;
        if (isNotScrollable && shouldCheck) {
          const lastPageLoaded = pagination?.preLoadedPages?.length
            ? Math.max(...pagination.preLoadedPages)
            : 0;
          setParams(prev => ({ ...prev, page: lastPageLoaded + 1 }));
        } else {
          shouldCheck = false;
          if (observerRef.current && containerRef.current) {
            observerRef.current.disconnect();
          }
        }
      }
    };

    debounceTimeout = setTimeout(checkIfScrollIsPossible, 500);

    observerRef.current = new MutationObserver(() => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(checkIfScrollIsPossible, 500);
    });

    if (containerRef?.current) {
      observerRef.current.observe(containerRef.current, {
        childList: true,
        subtree: true
      });
    }

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounceTimeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (observerRef?.current && containerRef?.current) {
        observerRef.current.disconnect();
      }
    };
  }, [
    properties?.length,
    isHugeOrganization,
    isLoadingFields,
    isLoadingPaginatedProperties,
    pagination?.preLoadedPages,
    setParams
  ]);

  const isRowSelected = useCallback(row => selectedProperty?.id === row.id, [
    selectedProperty?.id
  ]);

  const hasFullControl = useMemo(
    () => hasRoleAccess(role, ROLES.FULL_CONTROL),
    [role]
  );

  const expandOrCollapse = useCallback((isExpand, key) => {
    createAction(
      dispatch,
      isExpand ? EXPAND_PROPERTIES : COLLAPSE_PROPERTY,
      key
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = useCallback(
    (selectedKeys, info) => {
      const selectedKey = selectedKeys[0] ?? info.node.key;
      expandOrCollapse(!!selectedKeys[0], selectedKey);
    },
    [expandOrCollapse]
  );

  const onExpand = useCallback(
    (_, { expanded, node }) => {
      expandOrCollapse(expanded, node.key);
    },
    [expandOrCollapse]
  );

  const onUnarchiveProperty = property => {
    setUnarchivedProperty(property);
    setUnarchivedPropertyType(
      property.fields ? PropertyTypes.FARM : PropertyTypes.FIELD
    );
    setUnarchivePropertyModalOpen(true);
  };

  const onCropZoneRowSelect = useCallback(
    cropZone => {
      if (cropZone.archived) return;
      onPropertySelection(cropZone);
    },
    [onPropertySelection]
  );

  const treeData = propertiesFilter(properties, searchTextValueFFT)
    .sort((a, b) => a.parentPage - b.parentPage)
    .map(farm => {
      return {
        title: (
          <FarmRow
            farm={farm}
            onSelect={onPropertySelection}
            isSelected={isRowSelected(farm)}
            hasEditAccess={!farm.archived && hasFullControl}
            showUnarchiveButton={farm.archived && hasFullControl}
            onUnarchive={() => onUnarchiveProperty(farm)}
          />
        ),
        key: farm.id,
        children: farm.fields.map(field => {
          const fieldGeoJSON = findGeoJSON(
            fieldsAndAreasGeoJSONCollection,
            field
          );
          const fieldWithFarmId = { ...field, farmId: farm.id };

          return {
            title: (
              <FieldRow
                field={fieldWithFarmId}
                onSelect={onPropertySelection}
                isSelected={isRowSelected(field)}
                icon={fieldGeoJSON}
                hasEditAccess={!field.archived && hasFullControl}
                showUnarchiveButton={field.archived && hasFullControl}
                onUnarchive={() => onUnarchiveProperty(fieldWithFarmId)}
                parentPage={farm?.parentPage}
              />
            ),
            key: field.id,
            children: field.cropzones.map(cropZone => {
              const cropZoneGeoJSON = findGeoJSON(
                fieldsAndAreasGeoJSONCollection,
                cropZone
              );
              const cropZoneWithFieldIdAndFarmId = {
                ...cropZone,
                fieldId: field.id,
                farmId: farm.id,
                archived: field.archived
              };

              return {
                title: (
                  <CropZoneRow
                    onSelect={onCropZoneRowSelect}
                    isSelected={isRowSelected(cropZone)}
                    cropZone={cropZoneWithFieldIdAndFarmId}
                    icon={cropZoneGeoJSON}
                    fieldId={field.id}
                    isEditable={hasFullControl && !field.archived}
                    parentPage={farm?.parentPage}
                  />
                ),
                key: cropZone.id
              };
            })
          };
        })
      };
    });

  return (
    <>
      {isLoadingPaginatedProperties && loadingPosition === 'top' && (
        <div
          data-testid="top-loading"
          className="bg-neutral-100 border-b border-t border-neutral-20 py-4 flex items-center justify-center gap-2"
        >
          <div className="w-30px">
            <Spinner size="sm" />
          </div>
          <p className="test-sm">Loading previous farms...</p>
        </div>
      )}
      <div
        ref={containerRef}
        data-tree
        className="default-fft h-full overflow-auto"
      >
        <Tree
          selectedKeys={selectedProperty?.id ? [selectedProperty?.id] : []}
          treeData={treeData}
          onSelect={onSelect}
          onExpand={onExpand}
          expandedKeys={expandedPropertyIds}
          virtual
        />
        {unarchivedProperty.id && (
          <UnarchivePropertyModal
            open={unarchivePropertyModalOpen}
            propertyId={unarchivedProperty.id}
            propertyName={unarchivedProperty.name}
            propertyType={unarchivedPropertyType}
            reloadData={fetchProperties}
            close={closeUnarchivePropertyModal}
          />
        )}
      </div>
      {isLoadingPaginatedProperties && loadingPosition === 'bottom' && (
        <div
          data-testid="bottom-loading"
          className="bg-neutral-100 border-t border-b border-neutral-20 py-4 flex items-center justify-center gap-2"
        >
          <div className="w-30px">
            <Spinner size="sm" />
          </div>
          <p className="test-sm">
            {`Loading ${
              searchTextValueFFT?.valueInput ? '' : 'additional'
            } farms...`}
          </p>
        </div>
      )}
    </>
  );
};

DefaultTree.defaultProps = {
  properties: []
};

DefaultTree.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  propertiesFilter: PropTypes.func.isRequired
};

export default memo(DefaultTree);
