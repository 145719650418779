/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext
} from 'react';
import { Spinner } from '@agconnections/grow-ui';
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { uniqBy, flatten, cloneDeep, isEqual } from 'lodash';
import { Context } from 'components/Store';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import ClickableDiv from 'components/ClickableDiv';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import FormNavGuard from 'components/FormNavGuard';
import { roundValue } from 'helpers/unitConversionHelpers';
import { FETCH_TYPES } from 'helpers/constants';

import {
  IMPORTED_FIELDS,
  IS_FIELD_IMPORT_SUCCESSFUL,
  UPLOADED_FILES
} from 'reducers/reducer';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { paths } from 'routes/paths';
import PropertyDetailsToast from 'screens/Property/components/PropertyDetailsToast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropertyMapWrapper from '../PropertyLandingMap/PropertyMapWrapper';
import FieldCard from './components/FieldCard';
import PropertySelector from './components/PropertySelector';
import { CREATION_STATES } from './constants';
import useFarmData from '../../hooks/useFarmData';
import useFieldData from '../../hooks/useFieldData';
import {
  getFeaturesFromGeoJSONItems,
  getFeaturesCentroidCoordinates
} from '../../helpers/mapApiHelpers';
import {
  convertFeaturesToBaseGeometry,
  findGeoJSON,
  getUpdatedBoundingBox,
  getZoomByBounds,
  handleOverlappedCoordinates,
  mappingShape
} from '../../helpers/propertyDataHelpers';
import { fieldToFeature } from '../ImportPropertyModal/fieldImporterHelper';
import schema from './schema';

const LEAVING_CROP_ZONE =
  'Are you sure you do not want to create a cropzone also?';

const PropertyCreate = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { farms, createFarm } = useFarmData();
  const { fieldId, action } = useParams();
  const { createField, updateField } = useFieldData();
  const [{ importedFields, organization }, dispatch] = useContext(Context);
  const flags = useFlags();
  const { propertyCount } = organization?.properties || {};
  const isHugeOrganization =
    flags.configureLargeGrowerConditionals?.propertiesThreshold &&
    propertyCount >= flags.configureLargeGrowerConditionals.propertiesThreshold;

  const {
    fieldsAndAreasGeoJSONCollection,
    zoom: propertyZoom,
    setZoom: setPropertyZoom,
    fieldsLoading,
    isLoadingPaginatedProperties,
    setGeoJSONCollectionFeatures,
    loading,
    filterFeatureCollectionByParentId,
    reloadData
  } = usePopulateMapData({
    fetchType: isHugeOrganization
      ? FETCH_TYPES.paginatedPropertiesMap
      : FETCH_TYPES.propertiesMap
  });

  useEffect(() => {
    if (isHugeOrganization && state?.parentPage) {
      reloadData(null, { page: state.parentPage });
      return;
    }
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [features, setFeatures] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [shapeId, setShapeId] = useState(fieldId ? null : []);
  const [, setFieldName] = useState(fieldId ? 'Edit Field' : 'New Field');
  const [
    redirectToPropertiesLanding,
    setRedirectToPropertiesLanding
  ] = useState(!!fieldId);
  const [creationState, setCreationState] = useState(
    CREATION_STATES.DRAWING_MODE
  );
  const [redirect, setRedirect] = useState(null);
  const [formValues, setFormValues] = useState({
    name: '',
    reportedArea: '',
    propertyId: ''
  });
  const [newFieldFeatures, setNewFieldFeatures] = useState();

  const [fieldToEdit, setFieldToEdit] = useState();
  const [chkNavGuardDisabled, setChkNavGuardDisabled] = useState(false);
  const [isBackArrowClicked, setIsBackArrowClicked] = useState(false);
  const [fieldsFiltered, setFieldsFiltered] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  const fieldsLoaded = useRef(false);
  const navGuardDisabled = useRef(false);
  const boundsZoomStablished = useRef(false);

  useEffect(() => {
    if (!fieldId) {
      if (!state) return;
      const { selectedId } = state;

      if (selectedId) {
        setFormValues(previousFormValues => ({
          ...previousFormValues,
          propertyId: selectedId
        }));
      }
      if (selectedId && !loading && !isLoadingPaginatedProperties) {
        setGeoJSONCollectionFeatures(
          filterFeatureCollectionByParentId(
            fieldsAndAreasGeoJSONCollection,
            selectedId
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isLoadingPaginatedProperties]);

  const canShowMap = useMemo(() => {
    if (!fieldsLoading && !loading && !isLoadingPaginatedProperties) {
      if (fieldId) {
        return fieldsFiltered;
      }
      if (!fieldsAndAreasGeoJSONCollection.features.length) {
        return true;
      }

      return (
        !isEqual(propertyZoom, [4]) ||
        fieldsAndAreasGeoJSONCollection.features.length
      );
    }

    return false;
  }, [
    fieldId,
    fieldsFiltered,
    propertyZoom,
    fieldsAndAreasGeoJSONCollection.features,
    fieldsLoading,
    loading,
    isLoadingPaginatedProperties
  ]);

  const fitBounds = useMemo(
    () =>
      getUpdatedBoundingBox(undefined, fieldsAndAreasGeoJSONCollection, false),
    [fieldsAndAreasGeoJSONCollection]
  );

  const saveField = async values => {
    const field = {
      ...values
    };

    const featuresToGetGeometry = newFieldFeatures.map(ft => {
      if (ft.geometry.type === 'Polygon') {
        return {
          ...ft,
          geometry: {
            type: 'MultiPolygon',
            coordinates: [ft.geometry.coordinates]
          }
        };
      }

      return ft;
    });

    if (featuresToGetGeometry.length) {
      field.coordinates = getFeaturesCentroidCoordinates(
        featuresToGetGeometry
      ).toString();
    }
    field.geometry = convertFeaturesToBaseGeometry(featuresToGetGeometry);

    field.geometry.coordinates = flatten(field.geometry.coordinates);

    field.geometry.coordinates = handleOverlappedCoordinates(
      field.geometry.coordinates
    );

    field.reportedArea = field.reportedArea || 0;
    field.reportedAreaUnit = 'acre';

    if (!fieldId || state?.label === 'FROM_IMPORT_FIELD') {
      const { data: createdField } = await createField(field);
      return createdField;
    }

    const { data: updatedField } = await updateField(field, fieldId);
    return updatedField;
  };

  const onSubmit = (values, redirectToCropZone = false, setSubmitting) => {
    if (!redirectToCropZone && !fieldId) {
      setOpenModal(true);
    } else {
      PropertyCreate.saveProperty(values, redirectToCropZone, setSubmitting);
    }
  };

  PropertyCreate.saveProperty = async (
    values,
    redirectToCropZone,
    setSubmitting
  ) => {
    setCreationState(CREATION_STATES.SAVING);

    let createdField = null;
    let path = paths.properties;

    try {
      createdField = await saveField(values);

      if (redirectToCropZone) {
        path = `${paths.properties}/create/${createdField.id}/cropzone`;
        setRedirect(path);
      }

      if (redirectToPropertiesLanding) {
        if (state?.label === 'FROM_IMPORT_FIELD') {
          const removingTheFieldAfterSuccess = importedFields.filter(
            importedField => importedField.fieldId !== fieldId
          );
          if (removingTheFieldAfterSuccess.length > 0) {
            dispatch({
              type: IS_FIELD_IMPORT_SUCCESSFUL,
              payload: true
            });
          } else {
            dispatch({
              type: IS_FIELD_IMPORT_SUCCESSFUL,
              payload: false
            });
            dispatch({
              type: UPLOADED_FILES,
              payload: removingTheFieldAfterSuccess
            });
          }
          dispatch({
            type: IMPORTED_FIELDS,
            payload: removingTheFieldAfterSuccess
          });
        }
        setRedirect(path);
      } else if (!redirectToPropertiesLanding && !path?.includes('cropzone')) {
        window.location.reload(false);
      }
    } catch (err) {
      setIsToastVisible(true);
      setSubmitting(false);
      setCreationState(CREATION_STATES.GETTING_STARTED);
    }
  };

  const handleModalConfirm = useCallback(async (values, setSubmitting) => {
    setSubmitting(true);
    setOpenModal(false);

    await PropertyCreate.saveProperty(values, null, setSubmitting);

    setSubmitting(false);
  }, []);

  const handleModalCancel = useCallback((values, setSubmitting) => {
    setSubmitting(true);
    navGuardDisabled.current = true;
    PropertyCreate.saveProperty(values, true, setSubmitting);
  }, []);

  const handleModalClose = useCallback(setSubmitting => {
    setOpenModal(false);
    setSubmitting(false);
  }, []);

  const handleSaveAndCreateCropZone = useCallback((values, setSubmitting) => {
    setSubmitting(true);
    setRedirectToPropertiesLanding(false);

    onSubmit(values, true, setSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveAndRedirectToProperties = useCallback(
    (values, setSubmitting) => {
      setSubmitting(true);
      setRedirectToPropertiesLanding(true);

      onSubmit(values, false, setSubmitting);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCreateFarm = useCallback(
    name => {
      return createFarm({
        name,
        timeZone: 'US/Central',
        referencePoint: {
          type: 'Point',
          coordinates: [0, 0]
        }
      });
    },
    [createFarm]
  );

  const extractFeatureNames = useCallback(
    async feature => {
      const { farmName } = feature.properties;
      const existingFarm = farms.find(({ name }) => name === farmName);
      try {
        const newFarm =
          farmName && !existingFarm
            ? await handleCreateFarm(farmName)
            : existingFarm;

        setFormValues(previousFormValues => ({
          ...previousFormValues,
          name: feature.properties.fieldName || previousFormValues.name,
          propertyId: newFarm?.id || previousFormValues.propertyId
        }));

        return true;
      } catch (err) {
        return false;
      }
    },
    [farms, handleCreateFarm]
  );

  const handleFeaturesChange = useCallback(
    newFeatures => {
      const featureWithNewNames = newFeatures.find(
        ({ properties }) =>
          properties?.farmName ||
          properties?.fieldName ||
          properties?.cropZoneName
      );
      if (featureWithNewNames) {
        extractFeatureNames(featureWithNewNames);
      }
      const newShapes = getFeaturesFromGeoJSONItems(newFeatures);
      setFeatures(uniqBy(newShapes, 'id'));
    },
    [extractFeatureNames]
  );

  PropertyCreate.filterFields = () => {
    let selectedField = [];
    if (state?.label === 'FROM_IMPORT_FIELD') {
      const { selectedField: fieldFromImport } = state;
      selectedField = fieldToFeature(fieldFromImport);
      setFormValues({
        propertyId: farms.find(({ name }) => name === fieldFromImport?.farm)
          ?.id,
        name: fieldFromImport?.name,
        reportedArea: ''
      });
    } else {
      selectedField = findGeoJSON(fieldsAndAreasGeoJSONCollection, {
        id: fieldId
      });
      setFormValues({
        propertyId: selectedField?.properties?.propertyId,
        name: selectedField?.properties?.name,
        reportedArea: roundValue(selectedField?.properties?.reportedArea)
      });
    }

    const defaulField = cloneDeep(selectedField);

    defaulField.properties.$layer = 'default';
    selectedField.properties.$layer = 'selected';
    selectedField.id = fieldId;
    if (selectedField.geometry.coordinates.length === 1) {
      selectedField = mappingShape(selectedField);
    }

    const fields = [defaulField, selectedField];

    const customFitFields = getUpdatedBoundingBox(
      undefined,
      { type: 'FeatureCollection', features: fields },
      false
    );

    const boundsZoom = getZoomByBounds(customFitFields);

    setPropertyZoom([boundsZoom - 1]);
    setFieldToEdit(selectedField?.properties);
    setFieldName(selectedField?.properties?.name);
    setGeoJSONCollectionFeatures(fields, true);
    setNewFieldFeatures([selectedField]);
    setShapeId(fieldId);
    setFeatures(fields);
    setFieldsFiltered(true);
  };

  useEffect(() => {
    if (
      fieldId &&
      fieldsAndAreasGeoJSONCollection.features.length > 0 &&
      !fieldsLoaded.current &&
      farms.length > 0
    ) {
      fieldsLoaded.current = true;
      PropertyCreate.filterFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, fieldsAndAreasGeoJSONCollection, farms]);

  useEffect(() => {
    if (!boundsZoomStablished.current && !fieldId) {
      const boundsZoom = getZoomByBounds(fitBounds);

      if (!Number.isNaN(boundsZoom)) {
        boundsZoomStablished.current = true;
        setPropertyZoom([boundsZoom - 1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitBounds, fieldId]);

  const handleFeaturesSelect = useCallback(
    selectedIds => {
      const fields = features.filter(ft => selectedIds.includes(ft.id));
      const uniqFields = uniqBy(fields, 'id');
      setNewFieldFeatures(uniqFields);
      if (!selectedIds?.length && shapeId?.length > 0) {
        setShapeId([]);
      } else if (selectedIds?.length) {
        const idsToAdd = selectedIds.filter(sid => !shapeId.includes(sid));
        if (idsToAdd?.length) {
          setShapeId(currentState => {
            return [...currentState, ...idsToAdd];
          });
        } else if (idsToAdd?.length === 0) {
          const idsToRemove = selectedIds.filter(sid => shapeId.includes(sid));
          setShapeId([...idsToRemove]);
        }
      }
    },
    [features, shapeId]
  );

  const handleBackArrowCancel = () => {
    setIsBackArrowClicked(false);
    setChkNavGuardDisabled(false);
  };

  const handleCancelBackButton = () => {
    if (fieldId) {
      setChkNavGuardDisabled(true);
      history.goBack();
    } else {
      setRedirect(paths.properties);
    }
  };

  const isCreating = action === 'create';

  return (
    <>
      {redirect && <Redirect push to={`${redirect}`} />}
      <Breadcrumb disabled hideCropSeasonDropdown={!isCreating}>
        <Breadcrumb.Item
          title="Property"
          value="All Properties"
          to={paths.properties}
        />
        <Breadcrumb.Item
          title="Field"
          value={fieldId ? 'Edit Field' : 'New Field'}
          isLast
        />
      </Breadcrumb>
      <div data-testid="property-create-screen">
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={value => onSubmit(value, !!fieldId)}
          validationSchema={schema}
          validateOnChange
          validateOnMount
        >
          {({ values, setSubmitting, isValid, isSubmitting }) => {
            const canSave =
              creationState !== CREATION_STATES.SAVING &&
              isValid &&
              newFieldFeatures?.length > 0;

            return (
              <>
                <ConfirmationModal
                  open={openModal}
                  handleCancel={() => handleModalCancel(values, setSubmitting)}
                  handleClose={() => handleModalClose(setSubmitting)}
                  handleConfirm={() =>
                    handleModalConfirm(values, setSubmitting)
                  }
                >
                  Are you sure you want to create just a field and not a crop
                  zone with it?
                </ConfirmationModal>
                <ConfirmationModal
                  open={isBackArrowClicked}
                  handleCancel={handleBackArrowCancel}
                  handleConfirm={() => setRedirect(paths.properties)}
                  handleClose={handleBackArrowCancel}
                >
                  Are you sure you want to leave this page?
                </ConfirmationModal>
                <FormNavGuard
                  customText={!fieldId ? null : LEAVING_CROP_ZONE}
                  disabled={chkNavGuardDisabled || navGuardDisabled.current}
                  alwaysShow={!!fieldId}
                />
                <div className="bg-white flex w-full h-full">
                  <div
                    data-testid="property-create-left-panel"
                    className="flex flex-col w-1/3 h-auto m-4 relative"
                  >
                    <ClickableDiv className="flex-1 h-full">
                      <FieldCard
                        features={newFieldFeatures}
                        saveAndCreateCZ={() =>
                          handleSaveAndCreateCropZone(values, setSubmitting)
                        }
                        saveAndDirectToProperties={() =>
                          handleSaveAndRedirectToProperties(
                            values,
                            setSubmitting
                          )
                        }
                        canSave={canSave}
                        cancelBackButton={handleCancelBackButton}
                        label={action}
                        fieldId={fieldId}
                        setChkNavGuardDisabled={setChkNavGuardDisabled}
                        setIsBackArrowClicked={setIsBackArrowClicked}
                        chkSubmitStatus={isSubmitting}
                      >
                        <PropertySelector
                          onCreate={handleCreateFarm}
                          items={farms}
                          isDisabled={!isCreating}
                          selectedValue={fieldToEdit?.propertyId}
                          fieldsAndAreasGeoJSONCollection={
                            fieldsAndAreasGeoJSONCollection
                          }
                        />
                      </FieldCard>
                    </ClickableDiv>
                  </div>
                  <div
                    data-testid="property-create-right-panel"
                    className="relative w-full"
                    id="map-wrapper-container"
                  >
                    {canShowMap ? (
                      <PropertyMapWrapper
                        shapeIdSelected={shapeId}
                        geoJSONCollection={fieldsAndAreasGeoJSONCollection}
                        onFeaturesChange={handleFeaturesChange}
                        onSelectFeatures={handleFeaturesSelect}
                        liveUpdate={false}
                        zoom={propertyZoom}
                        setZoom={setPropertyZoom}
                        isCreate={!fieldId}
                        setNewFieldFeatures={setNewFieldFeatures}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
        {isToastVisible && (
          <PropertyDetailsToast
            state="error"
            selectedProperty={{ type: fieldToEdit.$landType }}
            showEditToast={isToastVisible}
            setShowEditToast={setIsToastVisible}
          />
        )}
      </div>
    </>
  );
};

export default PropertyCreate;
